import { getContext } from "svelte";
import { subscribe, getStore, resetStores } from "./livestores";
import * as Sentry from '@sentry/svelte';

let unsubscribe;

subscribe('GlobalChannel', { subject: 'prices' })

getStore('current_user').subscribe($currentUser => {
  if (!$currentUser) return
  
  Sentry.setUser({
    id: $currentUser.id,
    email: $currentUser.email,
    username: $currentUser.name
  })
})

export default function setupSubscriptions() {
  if (import.meta.env.SSR) return
  
  const { router, page } = getContext('inertia')
  
  window.addEventListener("message", async function (event) {
    if (event.data == "reload") {
      router.reload();
    }
  });
  
  page.subscribe(function($page) {
    if (!$page) return
    if ($page.props?.user_sgid) {
      if (unsubscribe) return
  
      unsubscribe = subscribe("UserChannel", {
        sgid: $page.props.user_sgid
      });
    } else if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      resetStores()
    }
  });
}