export function format(amount, symbol) {
  if (amount === null) return 'N/A'
  
  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: decimals(symbol)
  }).format(amount)
  
  if (symbol) return `${formatted} ${symbol.toUpperCase()}`
  else return formatted
}

// outputs <span class="gain">+100 EUR (+10%)</span>
// or <span class="loss">-100 EUR (-10%)</span>
export function diff(amount, percent, symbol) {
  if (amount === null) return 'N/A'

  const sign = amount >= 0 ? '+' : ''
  const formatted = `${sign}${format(amount, symbol)}`
  const percentStr = percent ? ` (${sign}${percent}%)` : ''
  const cssClass = amount >= 0 ? 'gain' : 'loss'

  return `<span class="${cssClass}">${formatted}${percentStr}</span>`
}

export function parse(n) {
  return parseFloat(n.toString().replace(/[^\d.]/g, ''))
}

function decimals(symbol) {
  if (symbol == "eur") return 2
  else return 2
}