import "@unocss/reset/tailwind.css";
import * as Sentry from '@sentry/svelte';
import {DEV} from 'esm-env'

import "~/global.css";

import axios from "axios";
import { createInertiaApp } from "inertiax-svelte";
import { hydrate } from "svelte";
import resolve from "../resolve";

import "~/lib/extensions";

if (!DEV) Sentry.init({
  environment: window.ENV,
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  dsn: "https://b0d1d2dd3953f2d3aa1ed462d5481d7f@o4506404440637440.ingest.sentry.io/4506404442013696",
})

const csrfToken = document.querySelector("meta[name=csrf-token]").content;
axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

createInertiaApp({
  resolve,
  setup({ el, App, props }) {
    hydrate(App, { target: el, props });
  },
});
