import { initStore } from '~/lib/livestores'
import { toast } from '~/ui'


// export default function () {
  const notifications = initStore('notifications', [])
  notifications.subscribe($notifications => {
    while ($notifications.length > 0) {
      const notification = $notifications.shift()
      toast.show(notification)
    }
  })
// }